<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Edit Item' }]" />
     <div v-if="loading" class="text-xs-center" style="padding:15px;">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-card v-else style="border-radius:10px 10px 0 0;">
      <v-toolbar card style="border-radius:10px 10px 0 0;" color="primary" dark>
        <v-toolbar-title>{{ nombre }} - {{ form.nombre }}</v-toolbar-title>
      </v-toolbar>
      <v-bottom-nav :active.sync="activeBtn" color="transparent" :value="true">
        <v-btn :to="{ name: 'EditItem', params: { id: $route.params.id } }" flat color="teal">
          <span>Informacion</span>
          <v-icon>assignment</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaPrecios', params: { id: $route.params.id } }" flat color="teal">
          <span>Precios</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ItemDimension', params: { id: $route.params.id } }" flat color="teal">
          <span>Peso</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListItemImagen', params: { id: $route.params.id } }" flat>
          <span>Imagenes</span>
          <v-icon>description</v-icon>
        </v-btn>
      </v-bottom-nav>
      <v-toolbar card color="grey darken-1" dark>
        <v-toolbar-title>Editar item</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-form ref="form" v-model="validForm" lazy-validation @submit.prevent="submitImagen">
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.nombre"
                :disabled="processingForm"
                label="Nombre"
                outline
                :rules="rules.nombre"
                :error="!!formErrors.nombre"
                :error-messages="formErrors.nombre"
                @keyup="
                  () => {
                    formErrors.nombre = undefined;
                    delete formErrors.nombre;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.SKU"
                :disabled="processingForm"
                label="SKU"
                outline
                :rules="rules.SKU"
                :error="!!formErrors.SKU"
                :error-messages="formErrors.SKU"
                @keyup="
                  () => {
                    formErrors.SKU = undefined;
                    delete formErrors.SKU;
                  }
                "
              />
            </v-flex>
              <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.costo"
                :disabled="processingForm"
                label="Costo"
                outline
                :rules="rules.costo"
                :error="!!formErrors.costo"
                :error-messages="formErrors.costo"
                @keyup="
                  () => {
                    formErrors.costo = undefined;
                    delete formErrors.costo;
                  }
                "
              />
            </v-flex>
             <v-flex md4 sm4 xs12>
              <v-text-field
                v-model="form.stock_minimo"
                :disabled="processingForm"
                label="Stock minimo"
                outline
                :rules="rules.stock_minimo"
                :error="!!formErrors.stock_minimo"
                :error-messages="formErrors.stock_minimo"
                @keyup="
                  () => {
                    formErrors.stock_minimo = undefined;
                    delete formErrors.stock_minimo;
                  }
                "
              />
            </v-flex>
             <v-flex md4 sm4 xs12>
              <v-text-field v-model="form.stock" disabled label="Stock" outline />
            </v-flex>
            <v-flex md4 sm4 xs12>
              <v-text-field v-model="form.precio" disabled label="Precio" outline />
            </v-flex>           
            <!-- <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.formato_venta"
                :items="tipos"
                dense
                outline
                clearable
                small-chips
                label="Formato de Venta"
                :error="!!formErrors.formato_venta"
                :error-messages="formErrors.formato_venta"
                @change="
                  () => {
                    formErrors.formato_venta = undefined;
                    delete formErrors.formato_venta;
                  }
                "
              />
            </v-flex> -->
            <v-flex md6 sm12 xs12 v-if="tipo === 'color'">
              <div
                style=" width: 100%; height: 60px; border: 2px solid rgba(0,0,0,0.54); border-radius: 4px;"
              >
                <label>Selecciona el color</label>
                <br />
                <input
                  type="color"
                  v-model="form.color"
                  class="input__color"
                  @change="codigoColor"
                />
              </div>
            </v-flex>
            <v-flex md6 sm12 xs12 v-if="tipo === 'color'">
              <v-text-field
                v-model="form.color_codigo"
                :disabled="processingForm"
                label="Color codigo"
                outline
                :rules="rules.color_codigo"
                :error="!!formErrors.color_codigo"
                :error-messages="formErrors.color_codigo"
                @keyup="
                  () => {
                    formErrors.color_codigo = undefined;
                    delete formErrors.color_codigo;
                  }
                "
              />
            </v-flex>           
            <v-flex md12 sm12 xs12 v-if="tipo === 'talla'">
              <v-text-field
                v-model="form.talla"
                :disabled="processingForm"
                label="Talla"
                outline
                :rules="rules.talla"
                :error="!!formErrors.talla"
                :error-messages="formErrors.talla"
                @keyup="
                  () => {
                    formErrors.talla = undefined;
                    delete formErrors.talla;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.estado"
                :items="estados"
                dense
                outline
                clearable
                small-chips
                label="Seleccionar Estado"
                item-text="nombre"
                item-value="id"
                :error="!!formErrors.estado"
                :error-messages="formErrors.estado"
                @change="
                  () => {
                    formErrors.estado = undefined;
                    delete formErrors.estado;
                  }
                "
              />
            </v-flex>
          </v-layout>
        </v-container>
        <center>
          <v-btn
            type="submit"
            color="success"
            :disabled="!validForm || processingForm"
            :loading="processingForm"
            >Guardar</v-btn
          >
          <v-btn
            color="error"
            @click="$router.push({ name: 'ListadoProductItems', params: { id: id } })"
          >
            Volver
          </v-btn>
        </center>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;
export default {
  metaInfo() {
    return { title: "Editar Item" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },
  data() {
    return {
      formErrors: [],
      activeBtn: 0,
      loading: false,
      stocks: [],
      form: {
        nombre: "",
        formato_venta: "",
        SKU: "",
        precio: "",
        costo: "",
        stock:"",
        color: "",
        color_codigo: "",
        stock_minimo: "",
        talla: "",
        estado: true
      },
      tipo: "",
      nombre: "",
      id: "",
      estados: [
        { id: false, nombre: "inactivo" },
        { id: true, nombre: "activo" }
      ],
      tipos: ["UN", "SACO"],
      validForm: true,
      processingForm: false,
      rules: {
        formato_venta: [v => !!v || "El formato de venta es requerido"]
      }
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },

  async created() {
    this.loading = true;
    await this.getItem({ itemId: this.$route.params.id }).then(response => {
      const itemInfo = response.data.data;
      this.setForm(itemInfo);
    });
    
    await this.getStocks(this.$route.params.id);    
    await this.getProducto({ productoId: this.id }).then(response => {
      this.tipo = response.data.data.tipo;
      this.nombre = response.data.data.nombre;
    });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      updateItem: "items/updateItem",
      getItem: "items/getItem",
      getEtiqueta: "etiquetas/getEtiqueta",
      getProducto: "productos/getProducto"
    }),
    codigoColor() {
      this.form.color_codigo = this.form.color;
    },
    async getStocks(id) {
      try {
        const res = await axios.get(`${HOST}/item-productos/${id}/stocks`);
        this.stocks = res.data.data;
        this.form.stock = this.stocks[0].stock;
      } catch (error) {
        console.log(error);
      }
    },
    setForm(item) {
      this.form.nombre = item.nombre;
      this.form.formato_venta = item.formato_venta;
      this.form.color = item.color;
      this.form.estado = item.estado;
      this.form.talla = item.talla;
      this.form.costo = item.costo;
      this.form.precio = item.precio_bruto;
      this.form.color_codigo = item.color_codigo;
      this.form.stock_disponible = item.stock_disponible;
      this.form.stock_minimo = item.stock_minimo;
      this.form.SKU = item.SKU;
      this.id = item.producto_id;
    },
    submitImagen() {
      if (!this.$refs.form.validate()) return false;
      this.processingForm = true;
      this.updateItem({ itemId: this.$route.params.id, data: this.form })
        .then(response => {
          this.processingForm = false;
         // this.$router.push({ name: "ListadoProductItems", params: { id: this.id } });
        })
        .catch(error => {
          this.processingForm = false;
          this.formErrors = error.response.data.errors || {};
        });
    }
  }
};
</script>

